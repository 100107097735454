<template>
  <div class="main">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
      <div class="">
        <div class="box center mB20">
          <el-form-item label="时段范围" prop="period">
            <el-select @change="clearData" v-model="ruleForm.period">
              <el-option v-for="item in periodList" :key="item.name" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <template v-if="ruleForm.period === 2">
            <el-form-item class="ml24" prop="startTime">
              <el-date-picker @change="clearData" type="datetime" :clearable="false" format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm" v-model="ruleForm.startTime">
              </el-date-picker>
            </el-form-item>
            <span class="line">-</span>
            <el-form-item prop="endTime">
              <el-date-picker @change="clearData" type="datetime" :clearable="false" format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm" v-model="ruleForm.endTime">
              </el-date-picker>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item class="ml24" prop="year">
              <el-date-picker @change="clearData" :clearable="false" v-model="ruleForm.year" type="year"
                placeholder="选择年">
              </el-date-picker>
            </el-form-item>
          </template>
          <el-button @click="queryData" class="mL44 dataQuery">查询</el-button>
        </div>
        <div v-if="ruleForm.period === 2" class="box center mB20">
          <el-form-item class="smallInput" label="时滞" prop="modalTimeLag">
            <el-input type="number" v-model="ruleForm.modalTimeLag" />
            <span class="fs18 mL10">s</span>
          </el-form-item>
          <el-form-item class="smallInput ml24" label="最低阶次" prop="modalNMin">
            <el-input-number :step-strictly="true" v-model="ruleForm.modalNMin" controls-position="right" :min="1"
              :max="10"></el-input-number>
          </el-form-item>
          <el-form-item class="smallInput ml24" label="最高阶次" prop="modalNMax">
            <el-input-number :step-strictly="true" v-model="ruleForm.modalNMax" controls-position="right" :min="1"
              :max="10"></el-input-number>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div v-if="ruleForm.period === 2 && isShowMain">
      <div class="myChart" id="myChart"></div>
      <div class="dataMain" v-if="pinlvList.length > 0">
        <ul class="dataList box center">
          <li>阶数</li>
          <li v-for="(item, index) in pinlvList" :key="index">{{ index + 1 }}</li>
        </ul>
        <ul class="dataList box center">
          <li>频率</li>
          <li v-for="(item, index) in pinlvList" :key="index">{{ item }}</li>
        </ul>
        <ul class="dataList box center">
          <li>阻尼比</li>
          <li v-for="(item, index) in zuniList" :key="index">{{ item }}</li>
        </ul>
      </div>
    </div>
    <template v-if="ruleForm.period === 1 && isShowMain">
      <el-table :span-method="objectSpanMethod" class="historyTable" :row-class-name="tableRowClassName"
        :data="tableData" style="width: 100%" v-loading="loading" element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <el-table-column align="center" prop="date" label="阶数">
          <el-table-column align="center" width="140" prop="date">
          </el-table-column>
          <el-table-column align="center" prop="name">
          </el-table-column>
        </el-table-column>
        <el-table-column align="center" v-for="(item, index) in tableColumns" :key="index" prop="value"
          :label="item + ''">
          <template slot-scope="scope">{{ scope.row.value[index] }}</template>
        </el-table-column>
      </el-table>
    </template>
  </div>
</template>

<script>
import echarts from "echarts";
let option = {
  dataZoom: [
    {
      type: 'inside',
      realtime: true,
      start: 0,
      end: 100
    }
  ],
  grid: {
    top: '50',
    left: '40',
    right: '20',
    bottom: '60'
  },
  xAxis: {
    nameTextStyle: {
      color: '#fff',
      padding: [61, 0, 0, -40]
    },
    name: 'f(Hz)',
    axisLabel: {
      color: '#fff',
      fontSize: 14
    },
    axisLine: {
      lineStyle: {
        color: '#33393A',
        type: 'dashed'
      },
    },
    axisTick: {
      show: false
    },
    splitLine: {
      lineStyle: {
        color: '#33393A'
      },
    },
  },
  yAxis: [
    {
      name: 'number of poles',
      nameTextStyle: {
        color: '#DAF704',
        padding: [0, 0, 10, 100],
        fontSize: 16
      },
      type: 'value',
      axisLabel: {
        color: '#fff',
        fontSize: 14
      },
      axisLine: {
        lineStyle: {
          color: '#979797'
        },
      },
      splitLine: {
        lineStyle: {
          color: '#33393A',
          type: 'dashed'
        },
      },
      axisTick: {
        show: false
      },
    }, {
      type: 'value',
      axisLine: {
        lineStyle: {
          color: '#979797'
        },
      },
      axisTick: {
        show: false
      },
    }
  ],
  legend: {
    data: [{ name: 'new pole' }, { name: 'stable pole' }, { name: 'stable freq. & MAC' }, { name: 'stable freq. & damp.' }, { name: 'stable freq.' }, { name: 'pwelch谱值', icon: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADaADAAQAAAABAAAADQAAAACpyhiuAAAAIUlEQVQoFWNgGH6AsevF//+keouJVA0g9YNcEzleGux6AG4JA3rtz4nzAAAAAElFTkSuQmCC' }],
    itemHeight: 11,
    itemWidth: 11,
    right: 30,
    textStyle: {
      color: '#fff',
      padding: [0, 10, 0, 0]
    },
    itemGap: 40
  },
  tooltip: {
    show: true,
    trigger: 'axis',
    // confine:true
  },
  series: [
    {
      name: 'new pole',
      symbolSize: 8,
      data: [],
      type: 'scatter',
      symbol: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAALCAYAAABLcGxfAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADKADAAQAAAABAAAACwAAAAChLCZNAAAArElEQVQoFWNkQAO5//+z/7nPcIuZhcFyihzjMzRpBiZ0Aa73DBxAMTmghBC6HIiPoQGbImQxkjUw5r79z8fwnYEPZsrfPwy8//8xXGNgZnBlYWa4ARP/85fh63R5xveMWff+P/7PwCADk8BFMzIw/BRjZBBgYeZn0CbWhgZ5xh9Ajaig/N1//k8fGD6wsjDoTpJjvIIqS49QwgjWb4IMP4DOePSPgeEdunNAfADyMTND4mn9UAAAAABJRU5ErkJggg==',
      itemStyle: {
        color: '#69DEFF'
      }
    },
    {
      name: 'stable pole',
      symbolSize: 8,
      data: [],
      type: 'scatter',
      symbol: 'circle',
      itemStyle: {
        color: '#FF7575'
      }
    },
    {
      name: 'stable freq. & MAC',
      symbolSize: 8,
      data: [],
      type: 'scatter',
      symbol: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAALCAYAAABLcGxfAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADKADAAQAAAABAAAACwAAAAChLCZNAAABIUlEQVQoFY1RsUoDURCcfXfGykYrCxGEcOJ1FlYBwc7WIlUMkYBg5Q8YCJgf8AuUIIHYWthZiYWdhlMTQQgi2NnYhHt367wjZ5UEt3j7hp3ZHXYF49CovojENiBaAnQdKm/M9/C8loQXXznPuI8+7W8jiXskW6IjmIVleKiz9I0kfdSoupsLRF+qS7Dag/ErEp7f5oU8k7yFFNcoYFOC9qeBRQOQy0lkJ5Kw/cB0hpG2HDZQlOi368DUMHNXEPIYhr4DFLzBVLIrbKy8s+mqatPnBOkjjoOZguePNTYeijQtJ+COTsszBWlcpnXynCUfp1xlRaODnUmibEvAMeblxNXFPdkdIB2iDlGXd3gFfopIdI+UQ96kxm3d/Aky0T8v/QswsmtziQfLwgAAAABJRU5ErkJggg==',
      itemStyle: {
        color: '#FFD25D'
      }
    },
    {
      name: 'stable freq. & damp.',
      symbolSize: 8,
      data: [],
      type: 'scatter',
      symbol: 'rect',
      itemStyle: {
        color: '#99ED6A'
      }
    },
    {
      name: 'stable freq.',
      symbolSize: 8,
      data: [],
      type: 'scatter',
      symbol: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADKADAAQAAAABAAAADAAAAAATDPpdAAAAt0lEQVQoFZVSORLCMAxc6aHQQQOBHlLBA0hSAZX5Ev+xkOyxsTMOM7jxoV1ptTKJO70BetD6OuHHEtfvILJhgO+ADPLquyW8uPMe8CMIz4AxtlbyMVDTcmye0MBzUgtMZb5YQQbt6aDlvWqeQHyk1WVMuIpgj1+SXmZgi2vT/62KkLNr5iBL/G3Rvdx04UbrLehpuZGEZlKYhdY1cBxK7UYi2J6lgju2cZve0roSbOf4bVh/gt9+AGA+gP/tCFrMAAAAAElFTkSuQmCC',
      itemStyle: {
        color: '#FFA07B'
      }
    },
    {
      name: 'pwelch谱值',
      data: [],
      type: 'line',
      symbol: 'none',
      itemStyle: {
        color: '#8AE8FF',
      },
      lineStyle: {
        width: 1
      },
    }
  ]
};
export default {
  name: "modalAnalysis",
  data() {
    return {
      ruleForm: { period: 2, startTime: '', endTime: '', year: '', modalTimeLag: 10, modalNMin: 1, modalNMax: 10 },
      rules: {},
      periodList: [{ name: '历史查询', id: 1 }, { name: '重新计算', id: 2 }],
      // jieshuList:[1,2,3,4,5,6],
      pinlvList: [],
      zuniList: [],
      tableColumns: [],
      tableData: [],
      isShowMain: false,
      loading: false,
    }
  },
  created() {
    this.$nextTick(() => {
    })
  },
  mounted() {
    this.getStartTime()
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {       //条纹变色
      if (rowIndex % 2 == 1) {
        return 'hui1';
      } else {
        return 'lv1';
      }
    },

    clearData() {
      this.isShowMain = false
    },
    queryData() {
      let period = this.ruleForm.period
      this.isShowMain = true
      if (period === 1) { // 历史计算
        this.getTable()
      } else { // 重新计算
        if (this.ruleForm.modalNMin >= this.ruleForm.modalNMax) {
          this.$message({
            message: "最低阶次应小于最高阶次",
            type: "warning"
          });
          return
        }
        this.$nextTick(() => {
          this.getLineData()
        })
      }
    },
    getStartTime() {
      this.ruleForm.endTime = new Date().Format('yyyy-MM-dd hh:mm')
      this.ruleForm.startTime = new Date(new Date().getTime() - 30 * 60 * 1000).Format('yyyy-MM-dd hh:mm')
      this.ruleForm.year = new Date().getFullYear() + ''
    },
    // 表格跨行
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    initEcharts() {
      let myChart = echarts.init(document.getElementById('myChart'));
      myChart.setOption(option)
      window.addEventListener("resize", () => { myChart.resize(); });
    },
    getLineData() { // 请求图表
      let projectId = this.$store.state.projectId
      // projectId = 10000
      let params = {
        startTime: this.ruleForm.startTime + ':00',
        endTime: this.ruleForm.endTime + ':00',
        projectId,
        modalTimeLag: this.ruleForm.modalTimeLag,
        modalNMin: this.ruleForm.modalNMin,
        modalNMax: this.ruleForm.modalNMax,
      }
      this.loadingPage = this.$loading({
        lock: true,
        text: '正在加载...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$axios.get(`${this.baseURL}data/hisData/modal/${projectId}`, { params }).then(res => {
        res = res.data
        console.log(66, res)
        let data = res.data
        this.loadingPage.close()
        if (res.errCode === '200') {
          /*图表散点展示：第一组：new pole
          第二组：stable pole
          第三组：stable freq. & MAC
          第四组：stable freq. & damp.
          第五组：stable freq.
          图表折线展示：第六组：[频率，幅值]列表展示：第七组：[频率，阻尼比]*/
          console.log(data)
          option.series[0].data = data[0]
          option.series[1].data = data[1]
          option.series[2].data = data[2]
          option.series[3].data = data[3]
          option.series[4].data = data[4]
          option.series[5].data = data[5]
          let pinlvList = []
          let zuniList = []
          /*for (let item of data[6]) {
            pinlvList.push(item[0].toFixed(4))
            zuniList.push(item[1].toFixed(4))
          }*/
          for (let i = 0; i < data[6].length / 2; i++) {
            let item = data[6][i]
            pinlvList.push(item.toFixed(4))
          }
          for (let i = data[6].length / 2; i < data[6].length; i++) {
            let item = data[6][i]
            zuniList.push(item.toFixed(4))
          }
          this.pinlvList = pinlvList
          this.zuniList = zuniList
          console.log(pinlvList)
          console.log(zuniList)
          this.initEcharts()
        } else {
          this.$message.error(res.errMsg)
        }
        this.loadingPage.close()
        this.initEcharts()
      })
    },
    getTable() {
      this.loading = true
      let projectId = this.$store.state.projectId
      let params = {
        projectId,
        time: this.ruleForm.year,
        sort: 1,
        size: 12 // 接口要求，暂定100，对前端目前没意义
      }
      this.$axios.get(`${this.baseURL}data/history/ssi/${projectId}`, { params }).then(res => {
        res = res.data
        let data = res.data
        console.log(res)
        let tableData = []
        let tableColumns = []
        if (res.errCode === '200') {
          for (let item of data) {
            let obj1 = {
              date: item.time,
              name: '频率',
            }
            let obj2 = {
              date: item.time,
              name: '阻尼比',
            }
            let objValue1 = []
            let objValue2 = []
            tableColumns = []
            for (let i = 0; i < item.data.length - 10; i++) {
              if (item.data[i] === 9999) {
                objValue1.push('-')
              } else {
                objValue1.push(item.data[i])
              }
              tableColumns.push(i + 1)
            }
            for (let i = 10; i < item.data.length; i++) {
              if (item.data[i] === 9999) {
                objValue2.push('-')
              } else {
                objValue2.push(item.data[i])
              }
            }
            obj1.value = objValue1
            obj2.value = objValue2
            tableData.push(obj1)
            tableData.push(obj2)
          }
          this.loading = false
          this.tableColumns = tableColumns
          this.tableData = tableData
        } else {
          this.loading = false
          this.$message.error(res.errMsg)
        }
      })
    },

  }
}
</script>

<style scoped lang="scss">
.main {
  ::v-deep .el-form {
    margin-top: 20px;

    .el-form-item__label {
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      text-align: left;
      line-height: 30px;
      width: 102px;
      padding: 0;
    }

    .el-form-item {
      margin-bottom: 0;
      display: flex;

      .el-form-item__content {
        margin-left: 0 !important;
        line-height: 30px;
      }
    }

    .line {
      padding: 0 10px;
    }

    .el-input {
      width: 134px;
      height: 30px;

      .el-input__inner {
        font-size: 16px;
        color: #fff;
      }

      .el-select__caret {
        line-height: 30px;
      }

      &.el-date-editor {
        width: 162px;

        .el-input__inner {
          padding: 0 0 0 10px;
          font-size: 14px;
        }

        .el-input__prefix {
          display: none;
        }
      }

    }

    .el-button.ml24 {
      margin-left: 24px;
    }
  }

  .myChart {
    width: 100%;
    height: 380px;
    overflow: hidden;
    //height: 420px;
  }

  .dataMain {
    border: 1px solid #979797;
    margin-right: 8px;
    margin-top: 30px;

    .dataList {
      border-bottom: 1px solid #33393A;

      li {
        width: 14.28%;
        height: 70px;
        line-height: 70px;
        text-align: center;
        border-left: 1px solid #33393A;

        &:first-child {
          border-left: none;
        }
      }
    }
  }

  ::v-deep .historyTable {
    border: 1px solid #31363A;

    thead.is-group.has-gutter {
      tr:nth-child(2) {
        display: none;
      }

      th {
        background-color: #31363A;
        border-color: #31363A;
      }
    }

    .el-table__body tr:hover>td {
      background-color: rgba(48, 46, 46, 0.11);
    }

    &.el-table--border::after,
    &.el-table--group::after {
      width: 0;
    }

    .el-table__header-wrapper {
      border-bottom: 1px solid #5E5E5F;
    }

    td {
      background-color: rgba(48, 46, 46, 0.11)
    }

    &.el-table td,
    &.el-table th.is-leaf {
      border-bottom: 1px solid #31363A !important;
    }

    &.el-table--border td,
    &.el-table--border th,
    &.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
      border-right: 1px solid #31363A !important;
    }
  }

  ::v-deep .smallInput {
    .el-input {
      width: 115px;

      .el-input__inner {
        padding: 0 15px;
      }
    }

    .el-input-number {
      width: 115px;

      span {
        display: none;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
}

::v-deep .hui1 {
  background: #091D46 !important;
}

::v-deep .lv1 {
  background: #0C265A !important;
}
</style>